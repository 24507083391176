import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../context/context";
import axios from "axios";
import { useParams } from "react-router-dom";
import TimezoneSelect from "react-timezone-select";
import { toast } from "react-toastify";
import "./device-settings.styles.scss";
import { convertedOffset } from "../../utilities/utils";
import { rolePriority } from "../../utilities/constants";

/*  Write Css */
function DeviceSettings() {
  const [userRole, setUserRole] = useState(null);
  const { deviceId } = useParams();
  const [nickname, setNickname] = useState("");
  const [oldNickname, setOldNickname] = useState("");
  const [oldTimezone, setOldTimezone] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState({});

  /*Context data */
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`/app-api/device/${deviceId}/settings`);
      setUserRole(data.role);
      setNickname(data.nickname);
      setOldNickname(data.nickname);
      setOldTimezone({
        value: data.timezoneValue,
        offset: data.timezoneOffset,
      });
      setSelectedTimezone({
        value: data.timezoneValue,
        offset: data.timezoneOffset,
      });
    }
    fetchData();
  }, []);

  /*To handle which role can change the timezone */
  const handleTimezoneError = async (e) => {
    toast.warn("Viewers cannot amend timezone");
    return;
  };

  /*Handle change of nickname */
  const handleChange = async (e) => {
    e.preventDefault();
    setNickname(e.target.value);
  };

  /*To handle nickname submission */
  const handleNicknameUpdate = async () => {
    if (nickname == " " || nickname == null) {
      toast.warn(`Please enter a nickname`);
      return;
    }
    if (nickname == oldNickname) {
      toast.error("Same as old Nickname");
      return;
    }
    const obj = {
      nickname,
    };
    const { data } = await axios.post(
      `/app-api/device/${deviceId}/nickname`,
      obj
    );

    /*Updating the context provider */
    const { nicknames } = state.user;

    Object.keys(nicknames).forEach((item) => {
      if (`${deviceId}` in nicknames) {
        nicknames[deviceId] = nickname;
      }
    });
    dispatch({ type: "CHANGE_NICKNAME", payload: state.user });

    /*Updating the session storage as context data is fetched from here */
    const sessionData = JSON.parse(window.sessionStorage.getItem("user"));
    sessionData.nicknames = state.user.nicknames;
    window.sessionStorage.setItem("user", JSON.stringify(sessionData));
    
    /*Setting old nickname */
    setOldNickname(obj.nickname);
    toast.success(data.message);
  };
  /*To handle Timezone submission */

  const handleOffsetUpdate = async () => {
    if (Object.keys(selectedTimezone).length == 0) {
      toast.warn("Please select a timezone");
      return;
    }
    if(selectedTimezone.value == oldTimezone.value){
      toast.error("Same Timezone");
      return;
    }
    const obj = {
      timezoneValue: selectedTimezone.value,
      timezoneOffset: selectedTimezone.offset * 60,
    };
  
    const { data } = await axios.post(
      `/app-api/device/${deviceId}/timezoneOffset`,
      obj
    );
    /*Setting old timezone to check for repeating update request */

    await setOldTimezone({
      value:selectedTimezone.value,
      offset:selectedTimezone.offset,
    });

    toast.success(data.message);
  };

  return (
    <div className="settings-page">
      <div className="box-container">
        <div className="input-nickname">
          <label>
            <h1>Nickname</h1>
            <input
              className="input"
              type="text"
              value={nickname}
              placeholder={"Enter Nickname"}
              onChange={(e) => handleChange(e)}
            />
          </label>
          <div className="button-container">
            <button className="clear-button" onClick={() => setNickname(" ")}>
              Clear
            </button>
            <button className="update-button" onClick={handleNicknameUpdate}>
              Update
            </button>
          </div>
        </div>

        <div className="input-offset">
          <label>
            <h1>Timezone</h1>
            {userRole > rolePriority.Viewer ? (
              <TimezoneSelect
                className="timezone-select"
                value={selectedTimezone}
                onChange={setSelectedTimezone}
                isSearchable
                isDisabled
                autoFocus
              />
            ) : (
              <TimezoneSelect
                className="timezone-select"
                value={selectedTimezone}
                onChange={handleTimezoneError}
                isSearchable
                isDisabled
                autoFocus
              />
            )}
          </label>
          <div className="button-container">
            <button className="update-button" onClick={handleOffsetUpdate}>
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceSettings;
